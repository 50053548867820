import _ from 'lodash';
import type { GetServerSideProps, NextPage } from 'next';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useEffect, useRef, useState } from 'react';
import { KEY_ADS_POSITION } from 'src/components/ads/dynamicAds';
import { SHOW_COMMEMORATE } from 'src/constants';
import { IS_NO_ROBOT_INDEX } from 'src/constants/index';
import { IMAGE_DEFAULT, WEB_DESC, WEB_KEYWORD, WEB_NAME, WEB_TITLE, WEB_URL } from 'src/constants/layout';
import { NAV_DATA } from 'src/redux/navSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { decryptionClient } from 'src/services/member-auth/utils/decryption-payload';
import { convertDatasets } from 'src/utils/datasets';
import { isShouldFetch } from 'src/utils/detect-scrolling';
import { convertObjPath, escapeChar, timestamp } from 'src/utils/helper';
import { GET } from 'src/utils/service';
import useUser from 'src/utils/useUser';
const Layout = dynamic(() => import('src/components/layouts'));

const SectionOne = dynamic(() => import('src/components/sections/section-home/section-one'), { ssr: true });
const SectionTwo = dynamic(() => import('src/components/sections/section-home/section-two'));
const SectionLandingPage = dynamic(() => import('src/components/sections/section-events/section-landing-page'));
const SectionCover = dynamic(() => import('src/components/sections/section-events/section-cover'));
const DynamicAds = dynamic(import('src/components/ads/dynamicAds'));

const sectionsNeedShow = ['section-two'];

const PageIndex: NextPage = ({
  articles,
  articlesTwo,
  event0,
  highlightText,
  dataLandingPage,
  covers,
  acticlesresDataPin,
  acticlesresDataPinOne,
  dataSetPinEmbed,
  dataSetPinEmbedOne,
  dataPopular,
  navBar,
  ads,
  sectionElection,
  dataSetSectionPride,
  sectionDiversity,
  sectionPrideData
}: any) => {
  const data = useUser();
  const [sessionSevId, setSessionSevId] = useState<any>(null);
  const [sectionMember, setSectionMember] = useState<any>({});

  useEffect(() => {
    try {
      const checkUser = async () => {
        if (!_.isEmpty(data) && !_.isEmpty(data?.user)) {
          const response = await fetch(`/api/auth/metadata`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (response.ok) {
            const res = await response.json();
            localStorage.setItem('login-meta-data', res);
            setSectionMember(decryptionClient(res));
          }
        }
      };

      const loginMetaData = localStorage.getItem('login-meta-data');
      const decryptLoginMetaData = !_.isEmpty(loginMetaData) ? decryptionClient(loginMetaData) : {};
      const expireCondition: boolean = !_.isEmpty(loginMetaData) ? new Date(decryptLoginMetaData?.expire) < new Date() : true;

      if (!_.isEmpty(data) && !_.isEmpty(data?.user)) {
        setSessionSevId(decryptionClient(data?.user));
        _.isEmpty(loginMetaData) || expireCondition ? checkUser() : setSectionMember(decryptLoginMetaData);
      }
    } catch (error) {
      console.log('Error: Please enable website cookies. ' + error);
    }
  }, [data]);

  const [sectionsVisible, setSectionsVisible]: any = useState([]);
  const nextSectionIndexRef = useRef(0);
  const sectionsVisibleRef: any = useRef([]);
  const _article = {
    title: articles?.metaTag?.title || WEB_TITLE,
    keywords: articles?.metaTag?.keywords || WEB_KEYWORD,
    description: articles?.metaTag?.description || WEB_DESC
  };

  const articleDescriptionSeo = escapeChar(_article?.description) || WEB_DESC;

  const navSubMenu = useAppSelector(props => props?.navData?.result?.[0]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const find = navBar && navBar?.filter((i: any) => i.nameEng === 'read');
    if (!_.isEmpty(find)) {
      dispatch(NAV_DATA(find));
    }
  }, []);
  /* ---------------------------- LOADMORE_ARTICLES --------------------------- */
  const handleScroll = async () => {
    if (isShouldFetch()) {
      document.removeEventListener('scroll', handleScroll);
      await loadMoreArticles();
    }
  };

  const loadMoreArticles = async () => {
    try {
      if (nextSectionIndexRef?.current < sectionsNeedShow.length) {
        if (isShouldFetch()) {
          const currentIndex = nextSectionIndexRef.current;
          const section = sectionsNeedShow[currentIndex];

          nextSectionIndexRef.current = currentIndex + 1;
          sectionsVisibleRef.current = [...sectionsVisibleRef.current, section];
          setSectionsVisible(sectionsVisibleRef.current);

          setTimeout(() => {
            loadMoreArticles();
          }, 1000);
        } else {
          document.addEventListener('scroll', handleScroll);
        }
      }
    } catch (err) {
      console.error(`${timestamp()} ==========> INDEX ERROR : ${nextSectionIndexRef.current}`, err);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {/* -------------------------------- NEXT_SEO -------------------------------- */}
      <NextSeo
        noindex={IS_NO_ROBOT_INDEX}
        nofollow={IS_NO_ROBOT_INDEX}
        title={_article.title}
        description={_article.description}
        openGraph={{
          type: 'article',
          locale: 'en_US',
          url: WEB_URL.WEB,
          title: _article.title || WEB_TITLE,
          description: _article.description || WEB_DESC,
          images: [
            {
              url: IMAGE_DEFAULT,
              width: 1920,
              height: 1080,
              alt: WEB_NAME
            }
          ]
        }}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: _article.keywords || WEB_KEYWORD
          }
        ]}
      />
      {/* --------------------------------- HEADER --------------------------------- */}
      <Head>
        <link rel='canonical' href={WEB_URL.WEB} />
        <meta property='twitter:title' content={_article?.title || WEB_TITLE} />
        <meta property='twitter:description' content={_article?.description || WEB_DESC} />
        <meta property='twitter:image' content={IMAGE_DEFAULT} />

        {/* --------------------------------- Truehit -------------------------------- */}
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
                __th_page="home";
              `
          }}
        />
        {/* ---------------------------- SNIPPET : WebSite --------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `
                  {
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "name": "${WEB_NAME}",
                    "url": "${WEB_URL.WEB}",
                    "description": "${articleDescriptionSeo}",
                    "sameAs": [
                      "${WEB_URL.FACEBOOK}",
                      "${WEB_URL.YOUTUBE}",
                      "${WEB_URL.INSTAGRAM}",
                      "${WEB_URL.TWITTER}"
                    ],
                    "potentialAction": {
                      "@type": "SearchAction",
                      "target": "${WEB_URL.WEB}search?q={search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                }
              `
          }}
        />

        {/* ---------------------------- SPIPPET : WebSite --------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `
                  {
                    "@context": "http://schema.org",
                    "@type": "WebPage",
                    "name": "${WEB_NAME}",
                    "description": "${articleDescriptionSeo}",
                    "publisher": {
                      "@type": "ProfilePage",
                      "name": "${WEB_NAME}"
                    }
                  }
                `
          }}
        />

        {/* <img
          width='99999'
          height='99999'
          alt='svasds'
          title='svasds'
          className='svgll'
          style={{
            pointerEvents: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '99vw',
            height: '99vh',
            maxWidth: '99vw',
            maxHeight: '99vh',
            overflow: 'hidden'
          }}
          src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI5OTk5OXB4IiBoZWlnaHQ9Ijk5OTk5cHgiIHZpZXdCb3g9IjAgMCA5OTk5OSA5OTk5OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZyBzdHJva2U9Im5vbmUiIGZpbGw9Im5vbmUiIGZpbGwtb3BhY2l0eT0iMCI+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9Ijk5OTk5IiBoZWlnaHQ9Ijk5OTk5Ij48L3JlY3Q+IDwvZz4gPC9zdmc+'
        ></img> */}
      </Head>

      {/* ------------------------ SectionLandingPage ----------------------- */}
      <SectionLandingPage data={dataLandingPage} />
      {/* ---------------------------- SECTION : COVER ------------------- */}
      {!_.isEmpty(covers) && <SectionCover data={covers} />}

      <Layout data={{ header: navBar, footer: navBar }} sessionSevId={sessionSevId}>
        <div
          style={{
            filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset'
          }}
        >
          <SectionOne
            data={articles}
            dataSets={event0}
            highlightText={highlightText}
            dataSetPinEmbed={dataSetPinEmbedOne}
            dataPopular={dataPopular}
            acticlesresDataPin={acticlesresDataPinOne}
            navBar={navBar}
            subMenu={navSubMenu}
            ads={ads}
            sectionMember={sectionMember}
            sectionElection={sectionElection}
            sectionDiversity={sectionDiversity}
            dataSetSectionPride={dataSetSectionPride}
            sectionPrideData={sectionPrideData}
          />
          {sectionsVisible.includes('section-two') && <SectionTwo data={articlesTwo} dataSetPinEmbed={dataSetPinEmbed} acticlesresDataPin={acticlesresDataPin} />}
          {/* Ads-PPN POSTARTICLE */}
          {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.POSTARTICLE} data={ads} />}
          {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.AD1X1} data={ads} />}
        </div>
      </Layout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx: any) => {
  const initProps: any = {
    statusCode: 200,
    errMessage: null,
    articles: {},
    articlesTwo: {},
    covers: [],
    dataLandingPage: [],
    dataPopular: [],
    event0: null,
    highlightText: [],
    acticlesresDataPin: null,
    acticlesresDataPinOne: null,
    dataSetPinEmbed: null,
    dataSetPinEmbedOne: null,
    navBar: [],
    ads: [],
    user: {},
    sectionElection: null,
    sectionDiversity: null,
    dataSetSectionPride: {
      sectionOne: [],
      sectionTwo: [],
      sectionThree: [],
      sectionFour: []
    },
    sectionPrideData: {
      sectionOne: [],
      sectionTwo: [],
      sectionThree: []
    }
  };

  const payload = {
    block1: 8,
    block2: 7,
    block3: 8,
    headerLatestNews: 5
  };

  try {
    const fetchImagesCover = await GET('/api/v1.0/imagecover');
    const fetchSectionOne = await GET(`/api/v1.0/home/section-one${convertObjPath(payload)}`);
    const fetchSectionTwo = await GET('/api/v1.0/home/section-two');
    const fetchPopularNews = await GET('/api/v1.0/categories/popular-news');
    const fetchDataSet = await GET('/api/datasets');
    const fetchLandingPage = await GET('/api/landing-page/list');
    const fetchMenuNav = await GET('/api/v1.0/navigations/menu-nav?limit=6');
    const fetchCategory = await GET('/api/v1.0/categories/social/diversity');
    // https://api-stag.thepeople.co/api/v1.0/categories/watch/diversity
    if (!_.isEmpty(fetchCategory)) {
      initProps.sectionPrideData.sectionThree = fetchCategory;
    }
    if (!_.isEmpty(fetchSectionOne)) {
      initProps.articles = fetchSectionOne;
      initProps.ads = fetchSectionOne?.ads;
      initProps.sectionPrideData.sectionOne = fetchSectionOne?.newHighlight2;
      initProps.sectionPrideData.sectionTwo = fetchSectionOne?.newHighlight3;
    }
    if (!_.isEmpty(fetchSectionTwo)) {
      initProps.articlesTwo = fetchSectionTwo;
    }
    if (!_.isEmpty(fetchPopularNews)) initProps.dataPopular = fetchPopularNews;

    if (!_.isEmpty(fetchDataSet)) {
      const dataSets = fetchDataSet; // default datasets
      initProps.event0 = _.find(dataSets, { topic: 'event-0' }) || [];
      initProps.highlightText = convertDatasets(fetchDataSet, 'highlight-text', false) || [];

      // election 2566
      initProps.sectionElection = _.find(fetchDataSet, { topic: 'section-election2566' })?.dataSet || [];
      initProps.dataSetSectionPride.sectionOne = _.find(fetchDataSet, { topic: 'section-pride-1' })?.dataSet || [];
      initProps.dataSetSectionPride.sectionTwo = _.find(fetchDataSet, { topic: 'section-pride-2' })?.dataSet || [];
      initProps.dataSetSectionPride.sectionThree = _.find(fetchDataSet, { topic: 'section-pride-3' })?.dataSet || [];
      initProps.dataSetSectionPride.sectionFour = _.find(fetchDataSet, { topic: 'section-pride-4' })?.dataSet || [];

      initProps.sectionDiversity = _.find(fetchDataSet, { topic: 'section-diversity' })?.dataSet || [];

      const findData = _.find(dataSets, { topic: 'lock-pin' }) || [];
      for (let i = 0; i < findData?.dataSet?.length; i++) {
        const element = findData?.dataSet[i]?.key === `lock-index-${fetchSectionTwo?.block1?.title}`;
        const elementEmbed = findData?.dataSet[i]?.key === `embed-index-${fetchSectionTwo.block1?.title}`;

        const elementS2 = findData?.dataSet[i]?.key === `lock-index-${fetchSectionTwo?.block3?.title}`;
        const elementEmbedS2 = findData?.dataSet[i]?.key === `embed-index-${fetchSectionTwo?.block3?.title}`;
        if (element) {
          const dataId = findData?.dataSet[i]?.value;
          const resDataPinId = `/api/v1.0/contents/${dataId}`;
          const resDataPin = await GET(resDataPinId);
          if (!_.isEmpty(resDataPin)) {
            initProps.acticlesresDataPin = resDataPin;
          }
        }
        if (elementEmbed) {
          initProps.dataSetPinEmbed = findData?.dataSet[i]?.value || [];
        }
        if (elementS2) {
          const dataId = findData?.dataSet[i]?.value;
          const resDataPinId = `/api/v1.0/contents/${dataId}`;
          const resDataPin = await GET(resDataPinId);
          if (!_.isEmpty(resDataPin)) {
            initProps.acticlesresDataPinOne = resDataPin;
          }
        }
        if (elementEmbedS2) {
          initProps.dataSetPinEmbedOne = findData?.dataSet[i]?.value || [];
        }
      }
    }

    if (!_.isEmpty(fetchImagesCover)) initProps.covers = fetchImagesCover;
    if (!_.isEmpty(fetchLandingPage)) {
      initProps.dataLandingPage = fetchLandingPage;
    }
    if (!_.isEmpty(fetchMenuNav)) initProps.navBar = fetchMenuNav;
  } catch (err: any) {
    console.error(`${timestamp()} ==========> PAGE_INDEX ERROR : `, err.message);
    initProps.errMessage = err.message;
    initProps.statusCode = err?.response?.status || 500;
  }

  return {
    props: initProps
  };
};

export default PageIndex;
